<template>
    <div class="screen1" :style="'background:url(' + bg + ') no-repeat'"> 
        <div class="d-flex">
            <div class="register_box">
              <div class="reg_title">¡Registrate <span> GRATIS</span>!</div>
              <div class="main_content" v-html="topText">Disfruta en pareja de <span>2 boletos de TEATRO </span> Más 3 días y 2 noches de hospedaje en en <span> ARIA OCEAN</span>  Nuevo Vallarta.</div>
            </div>
            <div class="form_box">
                <div class="blue_title">
                   Promoción realizada por
                </div>
                <div class="black_box">
                    <img src="../assets/img/logo.png" class="logo" alt="">

                    <p class="text-center">
                        Exclusivo para residentes de <span v-html="residentText"></span><br>
                        (* obligatorios)
                    </p>
                    <form action="" autocomplete="off">
                      <div class="form-group">
                        <input type="text" class="input input_big" placeholder="Nombre completo*" v-model="fullname" required autocomplete="off">
                        <input type="text" class="input input_small" placeholder="*Edad" v-model="age" autocomplete="off">
                      </div>
                      <div class="form-group">
                        <input type="email" class="input" placeholder="Correo Electrónico*" v-model="email" autocomplete="off">
                      </div>
                      
                      <div class="form-group">
                        <input type="text" class="input" placeholder="Teléfono Movil (10 dígitos)*" v-model="mobile" maxlength="10" autocomplete="off">
                      </div>
                    </form>
                  <button @click="clickBtn()" class="btn_capsul" disabled="true" id="btnParticipar">PARTICIPAR</button>
                  <!--<router-link :disabled="!benabled" :to="'screen2?city=' + queryText" tag="button" class="btn_capsul">PARTICIPAR</router-link>-->
                  
                </div>
            </div>
            <div class="form_privacy">
              <button @click="policy()" class="button_privacy"><img  src="../assets/img/banner.png" class="privacy" alt=""></button>
            </div>
        </div>
    </div>
</template>


<style scoped>
.screen1{
    background-size: cover !important;
    background-position: center !important;
    height: 120%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/*.form_box {
    margin-top: 30px;
}*/
.register_box {
    max-width: 380px;
    text-align: center;
    padding: 0 10px 55px;
    width: 100%;
    border: 0;
    background: url(../assets/img/bordered.png) no-repeat;
    background-size: 100%;
    margin: 0 auto;
}
.reg_title {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 300;
    line-height: 4px;
}
.main_content span,.black_box span{
  font-family:"Amelia-Basic-BoldItalic"
}
.reg_title span{
  color: #57bffd;
  font-family:"Amelia-Basic-Black"
}

@media(max-width:767px){
  .screen1 {
    padding: 30px 0;
    height: 100%;
  } 
}
@media(max-width:400px){
  .register_box {
    width: 95%;
    padding: 0 30px 20px;
  }
  .screen1 {
    height: 100%;
  } 
}
@media(max-width:350px){
  .reg_title {
    margin-bottom: 18px;
    font-size: 20px;
  }
  .main_content {
    font-size: 14px;
  }
  .register_box {
    padding: 0 20px 20px;
  }
  .screen1 {
    height: auto;
  } 
}
</style>
<script>
import tijuana_img from '../assets/img/FONDOPANTTJ01.png';
import cdmx_img from '../assets/img/FONDOPANTCDMX01.png';
import gdl_img from '../assets/img/FONDOPANTGDL01.png';
export default {
  name:"Screen1",
  data(){
    return{
      cities:[
          {
            name:"tijuana",
            top_content: "disfruta en pareja de una comida en el restaurante <span> SALVIA BLANCA </span> + 1 noche de hospedaje en el  <span>HOTEL BRISAS DEL VALLE </span> ubicado en el Valle de Guadalupe",
            resident_text: "Baja California",
            bg_img:tijuana_img
          },
          {
            name:"cdmx",
            top_content: "GRATIS y disfruta en pareja de una comida en Restaurantes <span> participantes + 2 BOLETOS DE AVION VIAJE</span> ",
            resident_text: "CDMX",
            bg_img:cdmx_img
          },
         {
            name:"gdl",
            top_content: "Disfruta en pareja de <span>2 boletos de TEATRO </span><br> Más 3 días y 2 noches de hospedaje en<br><span> ARIA OCEAN</span>  Nuevo Vallarta.",
            resident_text: "Jalisco",
             bg_img:gdl_img
          }
      ],
      topText:"",
      residentText:"",
      bg:"",
      queryText:"",
      fullname:"",
      age:"",
      email:"",
      mobile:"",
      benabled: false
    }    
  },
  mounted() {
    var  parameters = this.$route.query.city;
    this.clearLocalStorage();

    this.cities.map(city => {
      if(city.name == parameters){
        this.topText = city.top_content;
        this.bg = city.bg_img;
        this.residentText = city.resident_text;
        this.queryText = city.name
        localStorage.city = this.queryText;
      }
    })
  },
  methods: {
    checkFields() {
      this.benabled = false;
      document.getElementById("btnParticipar").disabled = true;
      if (this.fullname != '' && this.email !='' && this.age !='' && this.mobile !='') {
        if (isNaN(this.mobile) || isNaN(this.age)) {
          this.benabled = false;
          document.getElementById("btnParticipar").disabled = true;
        } else {
          document.getElementById("btnParticipar").disabled = false;
        }
      }
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    clearLocalStorage() {
      localStorage.fullname = '';
      localStorage.name = '';
      localStorage.email = '';
      localStorage.age = '';
      localStorage.mobile = '';
      localStorage.marital = '';
      localStorage.vacational = '';
      localStorage.frequency = '';
    },
    clickBtn() {
      console.log('clckbtn');
      this.checkFields();
      if (this.emailIsValid(this.email)) {
        this.$router.push('screen2?city='+this.queryText)
      } else {
        alert('Capture un correo electrónico válido');
      }
    },
    policy() {
      var city = localStorage.getItem('city');
      switch (city) {
          case 'tijuana':
              window.location.href='http://www.conocenos.travelzone.com.mx/premio-comida/';
              break;
          case 'cdmx':
              window.location.href='http://www.conocenos.travelzone.com.mx/8875-2/';
              break;
          case 'gdl':
              window.location.href='http://www.conocenos.travelzone.com.mx/premio-hospedaje-aria-ocean/';
              break;
          case '':
              this.$router.push('/?city='+this.queryText)
              break;
      }
    }
  },
  watch: {
    fullname(newFullName) {
      localStorage.fullname = newFullName;
      this.checkFields();
    },
    age(newAge){
      localStorage.age = newAge;
      this.checkFields();
    },
    email(newEmail) {
      localStorage.email = newEmail;
      this.checkFields();
    },
    mobile(newMobile) {
      localStorage.mobile = newMobile;
      this.checkFields();
    }
  }
}

</script>