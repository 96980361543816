import Vue from 'vue'
import VueRouter from 'vue-router'
import Screen1 from '../views/Screen1.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Screen1',
    component: Screen1
  },
  {
    path: '/screen2',
    name: 'Screen2',
    component: () => import('../views/Screen2.vue')
  },
  {
    path: '/screen3',
    name: 'Screen3',
    component: () => import('../views/Screen3.vue')
  }
]

const router = new VueRouter({
  routes,
   mode: 'history',
})

export default router
